import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import picOffice01 from '../assets/images/office-01.jpg'
import picCoffee02 from '../assets/images/coffee-02-min.jpg'
import picHotel01 from '../assets/images/hotel-01.jpg'
import picResidency from '../assets/images/stock-image-30-min.jpg'
import picHospital from '../assets/images/stock-image-21-min.jpg'
import picBar from '../assets/images/bar-01-min.jpg'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Parlour Party"
                    meta={[
                        { name: 'description', content: 'Board Game Service' },
                        { name: 'keywords', content: 'Board Game Service' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="two">
                        <div className="inner" style={{textAlign: "center"}}>
                            <h1>Browse Our Game Library</h1>
                            <ul className="actions">
                                <li><Link to="/browse" className="button next">Find A Game!</Link></li>
                            </ul>
                        </div>
                    </section>
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${picCoffee02})`}}>
                            <header className="major">
                                <h3>Coffee Shops</h3>
                                <p>Become a go-to spot for friends to gather and play board games, while increasing your profits.</p>
                            </header>
                            <Link to="s/how-it-work" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${picOffice01})`}}>
                            <header className="major">
                                <h3>Offices</h3>
                                <p>Our board game service is a perfect way to grow cross-department relations.</p>
                            </header>
                            <Link to="/office" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${picHotel01})`}}>
                            <header className="major">
                                <h3>Hotels</h3>
                                <p>Board Games are a cost-effective ammenity to offer to your guests.</p>
                            </header>
                            <Link to="/how-it-works" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${picResidency})`}}>
                            <header className="major">
                                <h3>Residencies</h3>
                                <p>Board games in the common area of your residency are a perfect ammenity to offer your tenants.</p>
                            </header>
                            <Link to="/how-it-works" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${picHospital})`}}>
                            <header className="major">
                                <h3>Hospitals</h3>
                                <p>In waiting areas, playing a board game is the perfect way to pass the time and keep children busy.</p>
                            </header>
                            <Link to="/how-it-works" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${picBar})`}}>
                            <header className="major">
                                <h3>Bars</h3>
                                <p>A selection of board games available to patrons can keep them a bit longer for a couple extra drinks.</p>
                            </header>
                            <Link to="/how-it-works" className="link primary"></Link>
                        </article>
                    </section>
                    
                </div>

            </Layout>
        )
    }
}

export default HomeIndex